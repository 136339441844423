import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'login',
  initialState: {
    token: ''
  },
  reducers: {
    setToken: (state,action) => {
      state.token=action
    },
  }
})
// 每个 case reducer 函数会生成对应的 Action creators
export const { setToken, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer