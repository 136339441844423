import React, { useState, useRef } from "react";
import { Button, Form, Input, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import { login, getSignInCode, resetPassword } from "../../http/modules/login";
import { useDispatch } from 'react-redux'
import { setToken } from '../../store/storeLogin'
import { Tabs } from "antd";
import './index.scss'

function JiGou(props) {

    const { wangJi, setWangJi, setIslogin } = props

    const dispatch = useDispatch()
    const navigate = useNavigate();

    // 登录
    const onFinish = async (values) => {
        try {
            const res = await login({
                "loginKey": values.loginKey,
                "password": values.password
            });
            if (res.code == 0) {
                localStorage.setItem('token', res.data.accessToken)
                localStorage.setItem('flag', res.data.flag)
                dispatch(setToken(res.data.accessToken))
                message.success("登录成功！");
                navigate("/organization/allCourses");
            } else {
                message.error(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Form
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                labelAlign={"left"}
            >
                <Form.Item
                    // label="用户名"
                    name="loginKey"
                    rules={[{ required: true, message: '请输入用户名/手机!' }]}
                >
                    <Input placeholder="用户名/手机号" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入正确密码!' }]}
                >
                    <Input.Password placeholder="密码" />
                </Form.Item>

                <div className="wangJi">
                    <div></div>
                    <div onClick={() => setWangJi(true)}>忘记密码</div>
                </div>

                <Form.Item className="name" style={{ marginTop: '40px' }}>
                    <Button
                        style={{ width: "419px", height: "56px", borderRadius: "10.5px", transition: "unset", fontSize: "22px" }}
                        type="primary"
                        htmlType="submit"
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

function ShengTing(props) {

    const { wangJi, setWangJi, setIslogin } = props

    const dispatch = useDispatch()
    const navigate = useNavigate();

    // 登录
    const unionOnFinish = async (values) => {
        const res = await login({
            loginKey: values.loginKey,
            password: values.password,
        });
        if (res.code == 0) {
            localStorage.setItem('flag', res.data.flag)
            localStorage.setItem('token', res.data.accessToken)
            dispatch(setToken(res.data.accessToken))
            message.success("登录成功！");
            navigate("/organization/allCourses");
        } else {
            message.error(res.msg);
        }
    };

    return (
        <div>
            <Form
                name="basic"
                onFinish={unionOnFinish}
                autoComplete="off"
                labelAlign={"left"}
            >
                <Form.Item
                    name="loginKey"
                    rules={[{ required: true, message: '请输入用户名!' }]}
                >
                    <Input placeholder="用户名/手机!" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入正确密码!' }]}
                >
                    <Input.Password placeholder="密码" />
                </Form.Item>

                <div className="wangJi">
                    <div></div>
                    <div onClick={() => setWangJi(true)}>忘记密码</div>
                </div>

                <Form.Item className="name" style={{ marginTop: '40px' }}>
                    <Button
                        style={{ width: "419px", height: "56px", borderRadius: "10.5px", transition: "unset", fontSize: "22px" }}
                        type="primary"
                        htmlType="submit"
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

function Register(props) {

    const { wangJi, setWangJi, setIslogin } = props


    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [sendDisable, setSendDisable] = useState(false);
    const timeRef = useRef(null);
    const [countdown, setCountdown] = useState(60);



    const sendMessage = () => {
        if (!phone) {
            message.error("请输入手机号");
            return;
        } else {
            if (!/^1[3456789]\d{9}$/.test(phone)) {
                message.success("手机格式有误");
                return;
            } else {
                timeRef.current = 60;
                setTimer();
                setSendDisable(true);
                getVerificationCode();
            }
        }
    };

    const setTimer = () => {
        let timer = setInterval(() => {
            if (timeRef.current > 0) {
                timeRef.current--;
                setCountdown(timeRef.current);
            } else {
                setCountdown(60);
                setSendDisable(false);
                clearInterval(timer);
            }
        }, 1000);
    };

    //短信验证码
    const getVerificationCode = async () => {
        try {
            const res = await getSignInCode({
                phone: phone
            })
            if (Number(res.code) == 0) {
                message.success('短信已成功发送')
            } else {
                message.success(res.msg)
            }
        } catch (error) {
            console.log(error);
        }
    };

    // 重置密码
    const onFinishReset = async (values) => {
        try {
            const res = await resetPassword({
                "phone": values.phone,
                "smsCode": values.smsCode,
                "password1": values.password,
                "password2": values.pw2,
            });
            if (res.code == 0) {
                message.success("密码重置成功");
                setWangJi(false)
            } else {
                message.error(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const items = [
        {
            key: "1",
            label: `企业家登录`,
            children: <JiGou setWangJi={setWangJi} wangJi={wangJi} setIslogin={setIslogin} />
        },
        {
            key: "2",
            label: `专家登录`,
            children: <ShengTing setWangJi={setWangJi} wangJi={wangJi} />
        }
    ];

    return (
        <div className="register">
            {wangJi ? <>
                <h1>重置密码</h1>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinishReset}
                    autoComplete="off"
                    labelAlign={"left"}
                >
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: "请输入正确的手机号" },
                        ]}
                    >
                        <Input
                            maxLength={11}
                            value={phone}
                            onChange={e => {
                                setPhone(e.target.value);
                            }}
                            placeholder="请输入手机号"
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "auto" }}
                        name="smsCode"
                        rules={[
                            { required: true, message: "请输入正确的验证码" },
                            { pattern: new RegExp(/^\d{6}$/), message: "请输入正确的验证码！" }
                        ]}
                    >
                        <Space>
                            <Input maxLength={6} value={code} onChange={e => setCode(e.target.value)} placeholder="请输入验证码" />
                            <Button disabled={sendDisable} type="link" onClick={sendMessage} /* className="anniu-fasong-yzms" */>
                                发送验证码
                                {sendDisable && <span>({countdown})</span>}
                            </Button>
                        </Space>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入密码!' }
                        ]}
                    >
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item
                        name="pw2"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject("两次密码输入不一致")
                                }
                            })
                        ]}
                    >
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item className="name" style={{ marginTop: '40px' }}>
                        <Button
                            style={{ width: "419px", height: "56px", borderRadius: "10.5px", transition: "unset", fontSize: "22px" }}
                            type="primary"
                            htmlType="submit"
                        >
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <footer><span>《</span><span style={{ marginLeft: '5px' }} onClick={() => setWangJi(false)}> 返回登录 </span></footer>
            </> :
                <Tabs items={items} />
            }
        </div>
    );
}

export default Register
