import React from 'react'
import './index.scss'
import { Table } from 'antd';

export default function index() {

  const columns = [
    {
      title: '评分内容',
      dataIndex: 'name',
      key: 'name',
      width:186,
      align:'center'
    },
    {
      title: '描述',
      dataIndex: 'age',
      key: 'age',
      align:'center'
    },
    {
      title: '分数',
      dataIndex: 'address',
      key: 'address',
      width:186,
      align:'center'
    },
  ];

  const dataSource = [
    {
      key: '1',
      name: '课程内容',
      age: '1.课程定位准确，紧扣企业发展痛点、紧盯受众对象需求，课程目标清晰，能提供可用、可靠的知识与技能。 2.有良好的整体结构，逻辑清晰、层次分明、重点突出，便于受众对象清晰且有效地理解、运用所学内容。 3.知识点科学合理，引用典型教学案例举例说明，具有示范作用和推广价值。',
      address: '40',
    },
    {
      key: '2',
      name: '教学效果',
      age: '1.具有科学的教学策略，能体现前沿性和实效性，提供实用的解决方案和实践经验 2.表现形式恰当，合理使用教具辅具，使教学内容呈现方式和表达方式更具多样性、直观性和适切性',
      address: '30',
    },
    {
      key: '3',
      name: '教师素养',
      age: '1.具有丰富的教学经验、教学能力和理论知识，能够有效地传授知识,，激发受众对象的学习兴趣 2.具有良好的思维逻辑和表达力，便于受众对象理解并掌握所学内容 3.具有创新思维与创造意识，能够灵活应对不同教学需求和环境，采用多种教学方法和策略提升教学效果。',
      address: '30',
    },
  ];

  return (
    <div className='rule'>
      <header>
        <p></p>
        线上评分标准
      </header>
      <Table dataSource={dataSource} columns={columns} pagination={false} bordered/>;
    </div>
  )
}
