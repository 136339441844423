import React, {  useLayoutEffect, useRef } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { Menu, Layout } from "antd";
import img from '../../img/header.png'
import { createFromIconfontCN } from '@ant-design/icons';
const { Content, Sider } = Layout;


export default function Index(props) {

  // icon
	const IconFont = createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/c/font_4294234_e01tufdvs7j.js' });

  const flag = localStorage.getItem('flag')

  function getItem(label, key,  icon, children,) {
    return { label, key,  icon, children, };
  }
  const items = [
    getItem('全部课程', 'allCourses',''),
    getItem('评分规则', 'rule'),
  ];

  // 点击当前菜单跳转页面
  const navigate = useNavigate();

  const clickMenu = (e) => {
    if (e.item.props.ispopup) {
      navigate('masking', { state: { uuid: e.item.props.uuid } })
    } else {
      navigate(e.key);
    }
  };

  const dataScreenRef = useRef(null);
  useLayoutEffect(() => {
      setScale()
  }, []);
  const setScale = () => {
      let designWidth = 1920;//设计稿的宽度，根据实际项目调整
      let designHeight = 1080;//设计稿的高度，根据实际项目调整
      let scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designWidth / designHeight ?
          (document.documentElement.clientWidth / designWidth) :
          (document.documentElement.clientHeight / designHeight);
      if (dataScreenRef.current) {
          dataScreenRef.current.style.transform = `scale(${scale}) translate(-50%, -50%)`
      }
  }

  window.onresize = function () {
      setScale()
  }

  return (
    <div className="dataScreen" ref={dataScreenRef}>
      <div className='layout'>
      <header className='header'>
        <div className='headerImg'>
          <img src={img} alt="" />
        </div>
        <div className='tuiChu' onClick={() => {
          localStorage.removeItem('token');localStorage.removeItem('flag');navigate('/')
        }}>
          退出
        </div>
      </header>
      <Layout className='layout' style={{ height: '90%' }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => {
          }}
          onCollapse={(collapsed, type) => {
          }}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['homeCentre','homeProvince']}
            defaultOpenKeys={['worksAdmin']}
            items={items}
            onClick={clickMenu}
          />
        </Sider>
        <Layout>
          <Content>
            <div style={{ padding: '37px ,0 ,0 54px' }}>
              <Outlet></Outlet>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
    </div>
    
  )
}
