import http from "../index";

// * 用户注册时的短信验证码
export const getSignInCode = (params) => {
	return http.post(`/api/v1/audit/user/unLoginSendSmsCode`, params);
};

// * 用户登录
export const login = (params) => {
	return http.post(`/api/v1/audit/user/login`, params);
};

// * 重置密码
export const resetPassword = (params) => {
	return http.post(`/api/v1/audit/user/resetPassword`, params);
};
