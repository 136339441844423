import http from "../index";

// * 课程类别
export const courseCategoryList = (params) => {
	return http.post(`/api/v1/audit/user/courseCategoryList`, params);
};

// 课程列表
export const listCourse = (params) => {
	return http.post(`/api/v1/audit/user/auditCourseList`, params);
};

// 课程详情
export const infoCourse = (params) => {
	return http.post(`/api/v1/audit/user/courseInfo`, params);
};

// 企业家打分
export const scoreCourseByOrg = (params) => {
	return http.post(`/api/v1/audit/user/scoreCourseByOrg`, params);
};

// 专家打分
export const scoreCourseByExpert = (params) => {
	return http.post(`/api/v1/audit/user/scoreCourseByExpert`, params);
};


