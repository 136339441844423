import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../views/login'
//机构
import Organization from '../views/organization/index.jsx'
import AllCourses from '../views/allCourses/index.jsx'
import Rule from '../views/rule/index.jsx';

import NotFound from '../views/notFound/index'

const IndexRouter = [
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/organization",
    element: <Organization />,
    children: [
      {
        path: "/organization/allCourses",
        element: <AllCourses />
      },
      {
        path: "/organization/rule",
        element: <Rule />
      },
    ]
  },
  {
    path: "*",
    element: <NotFound />
  },
]

const Router = () => {
  const routes = useRoutes(IndexRouter);
  return routes;
};

export default Router;
