import React from 'react'
import IndexRouter from './router/indexRouter'
import store from './store/index'
import { BrowserRouter, } from 'react-router-dom';
import { Provider } from 'react-redux'
import './app.scss'

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <IndexRouter />
        </BrowserRouter>
      </div>
    </Provider>
  )
}

export default App